<template>

  <div>
    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2 waybill-p-info"
          >
            <p>Request Log (only latest 20 records will be shown)</p>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2 text-right"
          >
            <b-button
              variant="outline-primary"
              @click="refresh"
            >
              <feather-icon
                icon="RefreshCwIcon"
                size="16"
              />
              <span class="waybill-refresh">Refresh</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="waybillDownloadFields"
        :items="waybills"
        primary-key="trade_no"
        show-empty
        striped
        :sticky-header="stickyHeader"
        :stacked="stacked"
        :borderless="false"
        min-row="10"
        empty-text="No matching records found"
      >
        <template #cell(target_name)="data">
          <b-link :href="data.item.target_name">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              :disabled="!data.item.target_name? true : false"
            >
              Download
            </b-button>
          </b-link>
        </template>
      </b-table>
    </b-card>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BTable, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'
import useJwt from '@/auth/jwt/useJwt'
import { showToast } from '@/libs/tool'
import usewaybillJs from './waybill'

const {
  uploadHistoryList,
} = usewaybillJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BTable,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      waybills: [],
      waybillDownloadFields: [
        { key: 'create_time', label: 'Create Time' },
        { key: 'original_name', label: 'File Name' },
        { key: 'status', label: 'Upload Result' },
        { key: 'update_time', label: 'Update Time' },
        { key: 'target_name', label: 'Status' },
      ],
      tzSelected: 'Etc/GMT+3',
      stacked: false,
      stickyHeader: false,
    }
  },
  mounted() {
    this.refresh()
  },
  created() {},
  methods: {
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    refresh() {
      this.loading = true
      const params = { access_token: useJwt.getToken() }
      uploadHistoryList(
        params,
        data => {
          this.loading = false
          this.waybills = data
        },
        fail => {
          showToast(this, 'Warning', `fetch upload list failed with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.waybill-p-info {
  margin-top: 10px;
}
.waybill-refresh {
  margin-left: 6px;
}
</style>
